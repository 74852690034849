/**
 * ClientAssessmentReport.jsx
 * ---------------------------
 * This component is responsible for rendering the client assessment report. The client assessment report is within
 * a tab in the main asssessment reporting page.
 *
 * Author: Oscar Jaimes
 */
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "../../styles/EngAssessment.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useParams } from 'react-router-dom';

// Report sections
import NutrientConcentration from "./horizontal-engineering-report/EngineeringAssessmentTables/Balance/NutrientConcentration";
import YearlyOutputs from "./horizontal-engineering-report/EngineeringAssessmentTables/Outputs/YearlyOutputs";
import TotalsChart from "./horizontal-engineering-report/EngineeringAssessmentTables/Outputs/TotalsChart";

// Client Report Sections
import Header from "./client-report-content/Header";
import ProcessFlowChart from './flow-charts/ProcessFlowChart';


import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

// Response Models
import {
  inputDataModel,
  nutrientConcentrationModel,
  yearlyTotalsModel,
  inputMassFlowModel,
  screwPressRemovalModel,
  microScreenSplitModel,
  ROModel,
} from "../../models/assessmentResponseModels";

import { InformationAndInputChart } from "./client-report-content/InformationAndInputChart";
import { useAuth } from "../../contexts/AuthContext";
import { SaveAndDownloadAssessmentReport } from "./SaveAndDownloadAssessmentReport";
import { getUserPermissionFromCache } from "../../utility/permissionFunctions";

// api
import { getAssessmentReport } from "../../api/reports";
import { runModel } from '../../api/carbonModelling';

import CarbonEmissions from "./horizontal-engineering-report/EngineeringAssessmentTables/CarbonEmissions";

// Language files
import ch from "./client-report-translations/ch";
import en from "./client-report-translations/en";
import ja from "./client-report-translations/ja";

export default function ClientAssesmentReport(props) {
  const params = useParams();
  const reportRef = useRef();
  const { currentUser } = useAuth();

  const carbonReportRef = useRef();

  // Loading state
  const [creatingPdf, setCreatingPdf] = useState(false);

  // State vars
  const [inputData, setInputData] = useState(inputDataModel);
  const [nutrientConcentrations, setNutrientConcentrations] = useState(nutrientConcentrationModel);
  const [yearlyTotals, setYearlyTotals] = useState(yearlyTotalsModel);
  const [inputMassFlows, setInputMassFlows] = useState(inputMassFlowModel);
  const [screwPressRemoval, setScrewPressRemoval] = useState(screwPressRemovalModel);
  const [microScreenSplit, setMicroScreenSplit] = useState(microScreenSplitModel);
  const [roFlows, setRoFlows] = useState(ROModel);
  const [ro2Flows, setRo2Flows] = useState(ROModel);
  const [isSaved, setIsSaved] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(en);
  const [currentLangStr, setCurrentLangStr] = useState("English");
  const [flocculantPerYear, setFlocculantPerYear] = useState(0);
  const [accountType, setAccountType] = useState("");
  const [polymerSystem, setPolymerSystem] = useState({
    'Units': '',
    'Polymer Dose (PPM)': 0,
    'Polymer Total Mass Flow': 0,
    'Dry Grō17 Mass Flow': 0,
    'Grō17 Water Mass Flow': 0,
  });

  const runCarbonModel = params.runCarbonModel === 'true' || params.runCarbonModel === true || params.runCarbonModel == 'true      ';
  const [carbonEmissions, setCarbonEmissions] = useState({
    net_emissions: 0,
    baseline_emissions: 0,
    leakage_emissions: 0,
    project_emissions: {
      solidStorageEmissions: 0,
      compostingEmissions: 0,
      electricityEmissions: 0,
      totalEmissions: 0,
    },
  });

  const getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    return yyyy + "/" + mm + "/" + dd;
  };

  useEffect(async () => {
    document.title = "Client Assessment Report";
    loadAssessmentData();
    setAccountType(await getUserPermissionFromCache(currentUser.email));
    params.name = params.name.split(" --time-- ")[0];
  }, []);

  const microNutrients = {
    calcium: params.calc || 0,
    magnesium: params.mag || 0,
    sodium: params.sod || 0,
    iron: params.iron || 0,
    manganese: params.manganese || 0,
    copper: params.copper || 0,
    zinc: params.zinc || 0,
    sulfur: params.sulfur || 0,
  };

  const loadAssessmentData = async () => {
    // Load query params
    const removal = params.removal;
    const operatingHours = params.hours;
    const operatingDays = params.days;
    const annualFlow = params.flow;
    const totalSolids = params.ts;
    const suspendedSolids = params.ss;
    const ammoniumNitrogen = params.ammNit;
    const tkn = params.tkNit;
    const potassium = params.pot;
    const phosphorus = params.phos;
    const volatileSolids = params.vs;
    const isFirstWave = params.sysType === "First Wave";
    const screwPressLoop = params.sp == "true" ? true : false;
    const screwPressRemoved = params.spRemoved == "true" ? true : false;
    const units = params.units;
    const roRecovery = params.roRecovery;
    const manure = params.manure;
    const secondRO = params.secondRO == "true";
    const ro2Recovery = params.ro2Recovery;
    const spOutput20Percent = params.spOutput20Percent == "true";
    const additional_constituents = JSON.parse(params.additional_constituents);
    const additional_constituents_ro_rejections = JSON.parse(params.additional_constituents_ro_rejections);
    const override_polymer_dose = params.override_polymer_dose == "true";
    const polymer_dose = Number(params.polymer_dose);

    const sysParams = {
      removal,
      operatingHours,
      operatingDays,
      annualFlow,
      totalSolids,
      suspendedSolids,
      ammoniumNitrogen,
      tkn,
      potassium,
      phosphorus,
      isFirstWave,
      screwPressLoop,
      screwPressRemoved,
      volatileSolids,
      roRecovery,
      reportType: "eng",
      units,
      manure,
      secondRO,
      ro2Recovery,
      spOutput20Percent,
      additional_constituents,
      additional_constituents_ro_rejections,
      override_polymer_dose,
      polymer_dose
    };

    const assessment = await getAssessmentReport(sysParams);

    setFlocculantPerYear(((Number(assessment['Mass Balance']['Input/Output']['Polymer Flow']) * 60 * 24 * 365) / 1000) * 0.005);
    setInputData(assessment["Input Data"]);
    setInputMassFlows(assessment["Input Mass Flows"]);
    setMicroScreenSplit(assessment["Microscreen Split"]);
    setScrewPressRemoval(assessment["Screw Press Removal"]);
    setRoFlows(assessment["Reverse Osmosis"]);
    setRo2Flows(assessment["Reverse Osmosis 2"])
    setYearlyTotals(assessment["Yearly Totals"]);
    setNutrientConcentrations(assessment["Nutrient Concentrations"]);
    setPolymerSystem(assessment['Polymer System']);


    if (runCarbonModel) {
      const carbonModel = await runModel({
        region: params.region.replace(/ /g, ''),
        baselineTreatmentType: params.baselineTreatment,
        livestockType: params.livestockType,
        numLivestock: Number(params.numLivestock) || 0,
        dryMatterIntake: Number(params.dryMatterIntake) || 0,
        manureSeparationPercent: Number(params.manureSeparation) / 100,
        separationEfficiency: Number(params.separationEfficiency) / 100,
        anerobicFraction: Number(params.anerobicTreatment) / 100,
        numberOfPumps: 1,
        pumpKw: Number(params.systemPower),
        pumpOperationTime: Number(params.operationTime),
        electricityRegion: params.electricityRegion.replace(/ /g, ''),
        yearlyCompostedWaste: Number(assessment["Yearly Totals"]['Metric']['LWR Solids']),
        flocculantPerYear: ((Number(assessment['Mass Balance']['Input/Output']['Polymer Flow']) * 60 * 24 * 365) / 1000) * 0.005,
        digestateKg: Number(params.digestateKg) || 0
      });

      setCarbonEmissions(carbonModel);
    }
  };

  const LanguageSelect = () => {
    const languages = [en, ch, ja];
    const langstrs = ["English", "Chinese", "Japanese"];

    return (
      <DropdownButton
        id="report-language"
        title={`Report Language: ${currentLangStr}`}
      >
        {langstrs.map((lang, index) => {
          return (
            <Dropdown.Item
              onSelect={() => {
                setCurrentLanguage(languages[index]);
                setCurrentLangStr(langstrs[index]);
              }}
            >
              {lang}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    );
  };

  return (
    <>
      <Row>
        <Col md={8} style={{ margin: 'auto', width: '83%' }}>
          <Row style={{ paddingTop: '0.5%', color: '#005E7D' }}>
            <Col>
              <h4>{
                params.name.toLowerCase().includes('client assessment')
                  ? params.name.split('--time--')[0]
                  : `${params.name} - Client Assessment`
              }</h4>
            </Col>
          </Row>
          <Row style={{ marginBottom: '1%', marginTop: '1%' }}>
            <Col md={2}>
              <SaveAndDownloadAssessmentReport
                params={params}
                isSaved={isSaved}
                inputData={inputData}
                currentUser={currentUser}
                accountType={accountType}
                setIsSaved={setIsSaved}
                massBalanceRef={{}}
                massBalanceView={true}
                reportRef={reportRef}
                setCreatingPdf={setCreatingPdf}
                engReportDiagram={false}
                creatingPdf={creatingPdf}
                microNutrients={microNutrients}
                downloadFormat={[16.5, 23.5]}
                reportType={"client"}
                carbonModel={{
                  region: params.region,
                  baselineTreatmentType: params.baselineTreatment,
                  livestockType: params.livestockType,
                  numLivestock: params.numLivestock,
                  dryMatterIntake: params.dryMatterIntake,
                  manureSeparationPercent: Number(params.manureSeparation) / 100,
                  separationEfficiency: Number(params.separationEfficiency) / 100,
                  anerobicFraction: Number(params.anerobicTreatment) / 100,
                  numberOfPumps: 1,
                  pumpKw: Number(params.systemPower),
                  pumpOperationTime: Number(params.operationTime),
                  electricityRegion: params.electricityRegion,
                  yearlyCompostedWaste: yearlyTotals['Metric']['LWR Solids'],
                  flocculantPerYear
                }}
              />

              {runCarbonModel && <Button style={{ marginTop: '10px' }} variant='outline-dark' onClick={() => {
                const element = document.querySelector("#carbon-report");
                const rect = element.getBoundingClientRect();

                html2canvas(element, {
                  windowWidth: rect.width,
                  windowHeight: rect.height
                }).then(canvas => {
                  const imgData = canvas.toDataURL('image/png');

                  // Convert the canvas dimensions from pixels to millimeters.
                  const widthInMm = (canvas.width * 0.264583) - 80;
                  const heightInMm = (canvas.height * 0.264583);

                  // Create a new PDF with custom dimensions
                  const pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'mm',
                    format: [widthInMm, heightInMm],
                    compress: true
                  });

                  // Add the image to the PDF.
                  pdf.addImage(imgData, 'PNG', 0, 0, widthInMm, heightInMm);
                  pdf.save(`Carbon Emissions Report: ${params.name}.pdf`);
                });
              }}>Download Carbon Emissions Report</Button>}
            </Col>
            <Col md={10} style={{ textAlign: 'right' }}>
              <LanguageSelect />
            </Col>
          </Row>
          <Row>
            <div
              className="report"
              style={{ padding: "65px", paddingTop: "30px" }}
              ref={reportRef}
            >
              <Header
                clientName={params.name}
                date={getDate()}
                units={params.units}
                language={currentLanguage}
              />
              <br />
              <br />
              <Row>
                <InformationAndInputChart
                  nutrients={microNutrients}
                  units={params.units}
                  manure={inputData["Manure"]}
                  data={inputData}
                  other={
                    inputData["Other Dissolved Solids (ppm)"]
                  }
                  language={currentLanguage}
                />
              </Row>
              <Row style={{ marginTop: "-30px" }}>
                <NutrientConcentration
                  outputLabel={true}
                  manure={inputData["Manure"]}
                  concentration={nutrientConcentrations}
                  units={params.units}
                  sysType={params.sysType}
                  language={currentLanguage}
                />
              </Row>
              <div className="row">
                <h5 style={{ textAlign: 'left', color: '#005E7D', fontWeight: 'bold', paddingTop: 30 }}>{props.language?.process_flow || 'Process Flow & Yearly Outputs'}</h5>
              </div>
              <Row style={{ height: '300px' }}>
                <div style={{ width: '100%', height: '100%' }}>
                  <ProcessFlowChart
                    inputData={inputMassFlows}
                    showPolymerDose={true}
                    polymerSystem={polymerSystem}
                    units={params.units}
                    roundUnits={false}
                    systemType={params.sysType}
                    manure={params.manure}
                    isFW={params.sysType === "First Wave"}
                    name={params.name.split(" --time-- ")[0]}
                    outputSolidsPerMinute={
                      params.spRemoved == "true"
                        ? microScreenSplit["Screw Press Mass Flows"]["Total Mass Flow"]
                        : screwPressRemoval["Mass Flows"]["Solids"]["Total Mass Flow"]
                    }
                    outputWaterPerMinute={
                      params.secondRO == "true" ? ro2Flows["Permeate"]["Total Mass Flow"] : roFlows["Permeate"]["Total Mass Flow"]}
                    outputLiquidsPerMinute={
                      roFlows["Concentrate"]["Total Mass Flow"] != 0
                        ? roFlows["Concentrate"]["Total Mass Flow"]
                        : microScreenSplit["MS Filtrate Mass Flows"]["Total Mass Flow"]
                    }
                  />
                </div>
              </Row>
              <Row>
                <YearlyOutputs
                  yearlyTotals={yearlyTotals}
                  units={params.units}
                  isFw={params.sysType === "First Wave"}
                  language={currentLanguage}
                  type={"client"}
                />
                <TotalsChart
                  type="client"
                  data={[
                    Number(yearlyTotals["LWR Solids"]["gallons"]),
                    Number(yearlyTotals["Nutrient Liquids"]),
                    Number(yearlyTotals["Clean Water"]),
                  ]}
                  language={currentLanguage}
                />
              </Row>
              <Row style={{ textAlign: "center", marginTop: 50 }}>
                <Col>
                  <p>© 2024 Livestock Water Recycling</p>
                </Col>
              </Row>
            </div>
          </Row>
          <br />
          <br />
          {runCarbonModel &&
            <div id="carbon-report">
              <CarbonEmissions
                params={params}
                other={{
                  yearlyCompostedWaste: Number(yearlyTotals['Metric']['LWR Solids']).toFixed(2),
                  flocculantPerYear
                }}
                carbonEmissions={carbonEmissions}
                ref={carbonReportRef} />
            </div>
          }
          <br />
        </Col>
      </Row>
    </>
  );
}
