import { max } from 'moment';
import React from 'react'
import { Chart } from "react-google-charts";

export default function PolymerGraph(props) {  
  const actualDoseNum = props.polymerdosedata[1][1].length;

  let chartData = Array.from({ length: actualDoseNum }, (_, index) => ({
    data: [
      props.polymerdosedata[0],
      ...props.polymerdosedata.slice(1).map(row => [
        row[0],
        Number(row[1][index]),
        Number(row[2]),
      ]),
    ],
  }));

    return (
        <div>
          {chartData.map((chart, index) => (
            <div key={index}>
            <Chart
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={chart.data}
                options={{
                    height: 450,
                    legend: { position: "top" },
                    chartArea: { width: "80%", height: "75%" },
                    title: "Polymer Dosage vs Date",
                    titleTextStyle: { fontFamily: "Arial", fontSize: 15, bold: true, italic: false },
                    hAxis: {
                        format: 'dd/MM/yy',
                        title: 'Date',
                    },
                    vAxis: {
                        title: 'ppm',
                        viewWindowMode: 'explicit',
                        viewWindow: {
                            min: 0,
                            max: Math.max(...chart.data.slice(1).flatMap(d => [d[1], d[2]])) * 1.5,
                        }
                    },
                    series: {
                        0: { targetAxisIndex: 0, color: "#1ECBE1" },
                        1: { targetAxisIndex: 0, color: "#9dd47a", type: "line" },
                    },
                }}
            />
            </div>
          ))}
        </div>
    )
}
